.tier-information {
	position: fixed;
	bottom: 0;
	background-color: $whitecolor;
	box-shadow: 0px -3px 15px 0px rgba(54, 54, 54, 0.6);
	-webkit-box-shadow: 0px -3px 15px 0px rgba(54, 54, 54, 0.6);
	-moz-box-shadow: 0px -3px 15px 0px rgba(54, 54, 54, 0.6);

	span,
	p {
		text-transform: uppercase;
		font-size: 14px;
		font-weight: bold;
		color: #666;
	}
	.c-stepper {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 0px;
		padding-left: 0;
	}

	.c-stepper__item {
		flex: 1;
		display: flex;
		flex-direction: column;
		text-align: center;
	}

	.c-stepper__item:before {
		--size: 1.6rem;
		content: attr(title);
		position: relative;
		z-index: 1;
		display: block;
		width: var(--size);
		height: var(--size);
		border-radius: 50%;
		margin: 0.2rem auto 0;
		background-color: #ccc;
		margin-bottom: 0;
		line-height: 26px;
		font-weight: bold;
		font-size: 14px;
	}
	.c-stepper__item_active{
		p{color: #000;}
	}
	
	.c-stepper__item_active:before {
		background-color: $themecolor;
		color: $whitecolor;
	}
	.c-stepper__item:not(:last-child):after {
		content: "";
		position: relative;
		top: 1.1rem;
		left: 50%;
		height: 2px;
		background-color: #e0e0e0;
		order: -1;
	}
	.c-stepper__item_active:after {
		background-color: $themecolor;
	}
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
	.c-stepper__title{display: none;}
}